import { graphql, useStaticQuery } from "gatsby";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import React from "react";
import { Helmet } from "react-helmet";
import CtaBanner from "../components/cta-banner";
import Layout from "../components/layout";
import TwoColumnContentWithTopImage from "../components/two-column-content-with-top-image";
import TwoColumnImageContent from "../components/two-column-image-content";

const NCZPortalPage = () => {
	const data = useStaticQuery(graphql`
		query {
			site {
				siteMetadata {
					siteUrl
				}
			}
			pageData: wpPage(slug: { eq: "ncz-portal" }) {
				seoFields {
					localBusinessSchema
					metaDescription
					opengraphDescription
					opengraphTitle
					title
					image {
						node {
							altText
							sourceUrl
							mediaDetails {
								height
								width
							}
						}
					}
				}
				nczPortal {
					topImageBanner {
						backgroundColor
						bannerHeading
						bannerContent
						backgroundColor
						bannerButton1 {
							title
							target
							url
						}
						bannerButton2 {
							title
							target
							url
						}
						bannerTopImage {
							node {
								altText
								sourceUrl
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: WEBP
											quality: 80
											transformOptions: { cropFocus: CENTER, fit: COVER }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
					nczTwoColumnSection {
						twoColumnSectionHeading
						twoColumnSectionContent
						twoColumnSectionSubHeading
						twoColumnSectionButton1 {
							title
							target
							url
						}
						twoColumnSectionButton2 {
							title
							target
							url
						}
						twoColumnSectionImage {
							node {
								altText
								sourceUrl
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: WEBP
											quality: 80
											transformOptions: { cropFocus: CENTER, fit: COVER }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
					nczBackgroundImageCta {
						sectionHeading
						sectionSubHeading
						sectionButton {
							title
							target
							url
						}
						sectionBackgroundImage {
							node {
								altText
								sourceUrl
							}
						}
					}
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;
	const {
		pageData: { seoFields, nczPortal },
	} = data;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "NCZ Portal",
				item: {
					url: `${siteUrl}/ncz-portal`,
					id: `${siteUrl}/ncz-portal`,
				},
			},
		],
	};

	return (
		<>
			<Helmet>
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title={seoFields?.title}
				description={seoFields?.metaDescription}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/ncz-portal`,
					title: `${seoFields?.opengraphTitle}`,
					description: `${seoFields?.opengraphDescription}`,
					images: [
						{
							url: `${seoFields?.image?.node.sourceUrl}`,
							width: `${seoFields?.image?.node.mediaDetails.width}`,
							height: `${seoFields?.image?.node.mediaDetails.height}`,
							alt: `${seoFields?.image?.node.altText}`,
						},
					],
				}}
			/>

			<Layout navBg={true}>
				<TwoColumnContentWithTopImage
					heading={nczPortal?.topImageBanner.bannerHeading}
					content={nczPortal?.topImageBanner.bannerContent}
					btnCta1={nczPortal?.topImageBanner.bannerButton1}
					btnCta2={nczPortal?.topImageBanner.bannerButton2}
					image={nczPortal?.topImageBanner.bannerTopImage?.node}
					bgColor={nczPortal?.topImageBanner.backgroundColor}
					border="0px"
				/>
				<TwoColumnImageContent
					heading={nczPortal?.nczTwoColumnSection.twoColumnSectionHeading}
					content={nczPortal?.nczTwoColumnSection.twoColumnSectionContent}
					tagline={nczPortal?.nczTwoColumnSection.twoColumnSectionSubHeading}
					btnCta1={nczPortal?.nczTwoColumnSection.twoColumnSectionButton1}
					btnCta2={nczPortal?.nczTwoColumnSection.twoColumnSectionButton2}
					image={nczPortal?.nczTwoColumnSection.twoColumnSectionImage?.node}
					flip={true}
					borderSize={12}
					borderColor={"#666"}
				/>
				<CtaBanner
					heading={nczPortal?.nczBackgroundImageCta.sectionHeading}
					content={nczPortal?.nczBackgroundImageCta.sectionSubHeading}
					btnCta1={nczPortal?.nczBackgroundImageCta.sectionButton}
					bgImage={
						nczPortal?.nczBackgroundImageCta.sectionBackgroundImage?.node
					}
					textPosition="center"
					isGreen={true}
					column={8}
				/>
			</Layout>
		</>
	);
};

export default NCZPortalPage;
